import React, { memo, useEffect } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "hooks";

import SmartLiveTextViewPermissionGuide from "./SmartLiveTextViewPermissionGuide";
import SmartLiveTextModeToggleButton from "./SmartLiveTextModeToggleButton";
import SmartLiveTextViewHeader from "./SmartLiveTextViewHeader";
import SmartLiveTextViewBody from "./SmartLiveTextViewBody";
import { useInitializeSLT } from "hooks/whisperSLTRealTime/useInitializeSLT";
import useSLTData from "hooks/whisperSLTRealTime/useSLTData";
import { setIsAutoNotesSettingsOpen } from "redux/modules/autoNotesSlice";
import useWhisperSLTStore from "store/useWhisperSLTStore";
import useNoteMateStore from "store/useNoteMateStore";

const SmartLiveTextView = memo(() => {
  useInitializeSLT();
  useSLTData();
  const { showSmartLiveTextView, setShowSmartLiveTextView } = useWhisperSLTStore();
  const dispatch = useAppDispatch();
  const { closeNoteMate } = useNoteMateStore();

  const { isAutoNotesSettingsOpen, isAutoNotesToggledOn } = useAppSelector((state) => state.autoNotes);

  useEffect(() => {
    if (showSmartLiveTextView) {
      dispatch(setIsAutoNotesSettingsOpen(false));
      closeNoteMate();
    }
  }, [showSmartLiveTextView]);

  useEffect(() => {
    if (isAutoNotesToggledOn) {
      setShowSmartLiveTextView(false);
    }
  }, [isAutoNotesToggledOn]);

  return (
    <TranscriptContainer showSmartLiveTextView={showSmartLiveTextView}>
      {showSmartLiveTextView && (
        <>
          <SmartLiveTextViewHeader />
          <SmartLiveTextModeToggleButton />
          <SmartLiveTextViewBody />
          <SmartLiveTextViewPermissionGuide />
        </>
      )}
    </TranscriptContainer>
  );
});

const TranscriptContainer = styled.div<{ showSmartLiveTextView: boolean }>`
  border-left: ${({ showSmartLiveTextView }) => (showSmartLiveTextView ? "1px solid var(--gray17)" : "0")};
  height: 100%;
  max-width: 400px;
  min-width: ${({ showSmartLiveTextView }) => (showSmartLiveTextView ? "400px" : "0")};
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--gray19);

  * {
    &::selection {
      background: rgba(27, 100, 218, 0.6) !important;
    }
  }
`;

export default SmartLiveTextView;
