import React, { useState, useEffect } from "react";
import styles from "./VideoDocument.module.scss";
import Split from "react-split";
import VideoPlayer from "../../components/VideoDocument/VideoPlayer/VideoPlayer";
import VideoDocumentEditor from "../../components/VideoDocument/VideoDocumentEditor";
import EditorLoadingScreen from "components/editorLoadingStates/EditorLoadingScreen";
import { fetchDocument, fetchDocuments, setDocument, setIsEditorLoading } from "redux/actions/vdocsActions";
import { fetchUser, updateUser, patchStudyChallengeData } from "redux/actions/slidGlobalActions";
import { sendSplitOnDragStartRequestToParentWindow, sendSplitOnDragEndRequestToParentWindow, sendDisplayVideoWithEditorRequestToParentWindow } from "utils/extensionInterface/sendToExtension";
import { fetchCurrentFolder } from "components/myNotes/folder/folder.utils";
import RealTimeWhisperSLTView from "components/SmartLiveTextWhisperRealTime/SmartLiveTextView";
import AutoNotesSideBarView from "components/smartautonotes/AutoNotesSideBar/AutoNotesSideBarView";
import { isUserAuthenticated } from "utils/auth/cognitoAuthToolkit";
import BrowserInteractionTime from "browser-interaction-time";
import { SlidFeatures } from "utils/privilegeManager";
import USER_PRIVILEGES from "utils/privilegeManager";
import { showPlanNeededModal } from "utils/modal";
import DesktopSmartLiveTextView from "../../components/DesktopSmartLiveText/DesktopSmartLiveTextView";
import { useAppDispatch, useAppSelector } from "hooks";
import useWhisperSLTStore from "store/useWhisperSLTStore";
import { useModal } from "@slid/slid-ips";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import useEditorStore from "store/useEditorStore";
import { useImageNodeStore } from "components/NewEditor/store/useImageNodeStore";
import NoteMateChat from "components/NoteMate/NoteMateChat";
import useNoteMateStore from "store/useNoteMateStore";

export const browserInteractionTime = new BrowserInteractionTime({
  timeIntervalEllapsedCallbacks: [],
  absoluteTimeEllapsedCallbacks: [],
  browserTabInactiveCallbacks: [],
  browserTabActiveCallbacks: [],
  idleTimeoutMs: 3000,
  checkCallbacksIntervalMs: 10000,
});

const VideoDocument = ({ documentKey }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isMobile = useMediaQuery({ query: "(max-width:799px)" });
  const { userData, applicationType, screenOrientation, notifications, userStudyChallengeData } = useAppSelector((state) => state.slidGlobal);
  const { currentDocument, editorIsDisplayed, isEditorLoading, isSTTSupported, currentVideo } = useAppSelector((state) => state.vdocs);
  const { showImageMarkupPopup, showImageCroppingModal } = useImageNodeStore();
  const { isAutoNotesSupported } = useAppSelector((state) => state.autoNotes);
  const { showSmartLiveTextView, isRealTimeTranscriptSupported } = useWhisperSLTStore();
  const { isNoteMateOpen } = useNoteMateStore();
  const { showModal, closeModal, modalOn } = useModal();
  const { setIsFetchingDocument } = useEditorStore();
  const [splitGutterSize] = useState(7);
  const [isSmallVideoPlayer, setIsSmallVideoPlayer] = useState(false);
  const [isBrowserInteractionIntervalCallbackSet, setIsBrowserInteractionIntervalCallbackSet] = useState(false);
  const { isAutoNotesSettingsOpen } = useAppSelector((state) => state.autoNotes);
  const [splitSizes, setSplitSizes] = useState([60, 40]);

  const setBrowserInteractionIntervalCallback = () => {
    setIsBrowserInteractionIntervalCallbackSet(true);
    browserInteractionTime.startTimer();
    browserInteractionTime.addTimeIntervalEllapsedCallback({
      multiplier: (time) => time,
      timeInMilliseconds: 10000,
      callback: () => {
        if (!browserInteractionTime.isRunning()) return;
        if (userStudyChallengeData?.isActive) {
          dispatch(
            patchStudyChallengeData({
              data: {
                event_name: "14_DAYS_STUDY_CHALLENGE",
                date: userStudyChallengeData.currentDate,
                study_time: 10,
              },
            })
          );
        } else {
          try {
            const userDataMoreInfo = JSON.parse(userData.more_info) || {};
            dispatch(
              updateUser({
                data: {
                  ...userData,
                  more_info: {
                    ...userDataMoreInfo,
                    total_learning_time: userDataMoreInfo?.total_learning_time ? userDataMoreInfo?.total_learning_time + 10000 : 10000,
                  },
                },
              })
            );
          } catch {}
        }
      },
      // @ts-ignore
      pending: true,
    });
  };

  useEffect(() => {
    if (currentVideo) {
      sendDisplayVideoWithEditorRequestToParentWindow();
    }
  }, [currentVideo]);

  useEffect(() => {
    dispatch(setIsEditorLoading(true));

    return () => {
      browserInteractionTime.destroy();
    };
  }, []);

  useEffect(() => {
    const initializeComponent = async () => {
      const isAuthenticated = await isUserAuthenticated();
      if (isAuthenticated) {
        if (!userData) {
          await dispatch(fetchUser());
        }
      }
      if (!isBrowserInteractionIntervalCallbackSet && userData && userData.payment === "trial") {
        setBrowserInteractionIntervalCallback();
      }
    };

    initializeComponent();
  }, [userData]);

  useEffect(() => {
    if (!applicationType || !documentKey) return;
    const setCurrentDocument = async () => {
      const isAuthenticated = await isUserAuthenticated();
      if (isAuthenticated) {
        if (documentKey === "new") {
          if (!currentDocument?.mapped_videos) dispatch(setDocument(null));
          await fetchCurrentFolder({
            documentKey: "root",
          });
          useEditorStore.setState({ currentDocumentKey: "new" });
          if (applicationType !== "desktop" && isMobile) {
            history.replace(`/docs/new`);
          }
        } else {
          setIsFetchingDocument(true);

          if (applicationType === "extension") {
            await dispatch(
              fetchDocument({
                documentKey: documentKey,
                showError: true,
              })
            );

            await fetchCurrentFolder({
              documentKey: currentDocument?.parent_key || "root",
            });
          } else {
            await dispatch(
              fetchDocument({
                documentKey: documentKey,
              })
            );
            await fetchCurrentFolder({
              documentKey: currentDocument?.parent_key || "root",
            });
          }
        }
      }
    };

    setCurrentDocument();
    dispatch(fetchDocuments());
  }, [applicationType, documentKey]);

  useEffect(() => {
    if (currentDocument) {
      useEditorStore.setState({ currentDocumentKey: currentDocument["document_key"] });
    }
  }, [currentDocument]);

  useEffect(() => {
    if (showImageMarkupPopup || showSmartLiveTextView || showImageCroppingModal) {
      const gutter = document.getElementById("gutter");
      if (gutter) {
        gutter.classList.toggle(`${styles[`dark-gutter`]}`, showImageMarkupPopup || showSmartLiveTextView || showImageCroppingModal);
      }
    }
  }, [showImageMarkupPopup, showSmartLiveTextView, showImageCroppingModal]);

  useEffect(() => {
    const checkEditorLoading = async () => {
      if (isEditorLoading) {
        if (userData?.payment && !USER_PRIVILEGES.has(SlidFeatures.accessVideoDocument)) {
          if (!notifications?.length && !modalOn) {
            showPlanNeededModal({
              showModal: showModal,
              closeModal: closeModal,
              history: history,
            });
          }
        }
      }
    };

    checkEditorLoading();
  }, [isEditorLoading, userData, notifications, modalOn, showModal, closeModal]);

  useEffect(() => {
    const size = splitSizes;
    if ((size[0] * window.innerWidth) / 100 <= 644) {
      setIsSmallVideoPlayer(true);
    } else if ((size[0] * window.innerWidth) / 100 - 400 <= 644 && (isNoteMateOpen || showSmartLiveTextView || isAutoNotesSettingsOpen)) {
      // When NoteMate, SmartLiveTextView, or AutoNotesSettings is open, calculate the size of the video player excluding the sidebar
      setIsSmallVideoPlayer(true);
    } else {
      setIsSmallVideoPlayer(false);
    }
  }, [isNoteMateOpen, showSmartLiveTextView, isAutoNotesSettingsOpen, splitSizes]);

  if (applicationType === "desktop" && !isSTTSupported) {
    return (
      <div className={`${styles[`desktop-document-container`]}`}>
        <VideoDocumentEditor />
      </div>
    );
  }
  if (applicationType === "desktop" && isSTTSupported) {
    return (
      <div className={styles[`stt-desktop-document-container`]}>
        <div className={styles[`stt-desktop-transcript-container`]}>
          <DesktopSmartLiveTextView />
        </div>
        <div className={styles[`desktop-document-container`]}>
          <VideoDocumentEditor />
        </div>
      </div>
    );
  }
  return (
    <>
      <EditorLoadingScreen />
      <div className={`${styles[`vdocs-container`]}`}>
        {screenOrientation === "vertical" ? (
          <div className={styles[`vertical-vdocs-container`]}>
            <div className={styles[`vertical-videoplayer-container`]}>
              <VideoPlayer />
            </div>
            <div className={styles[`vertical-document-container`]}>
              <VideoDocumentEditor />
            </div>
          </div>
        ) : (
          <Split
            className={`${styles[`split-wrapper`]} d-flex`}
            sizes={editorIsDisplayed ? [60, 40] : [100, 0]}
            minSize={editorIsDisplayed ? [580, 330] : [580, 0]}
            expandToMin={false}
            gutter={(index, direction) => {
              const gutter = document.createElement("div");
              gutter.id = `gutter`;
              gutter.className = `gutter gutter-${direction} ${styles[`gutter`]}`;
              return gutter;
            }}
            gutterSize={splitGutterSize}
            gutterAlign="center"
            snapOffset={30}
            dragInterval={1}
            direction="horizontal"
            cursor="col-resize"
            onDragStart={(size) => {
              setSplitSizes(size);
              sendSplitOnDragStartRequestToParentWindow();
            }}
            onDragEnd={(size) => {
              setSplitSizes(size);
              sendSplitOnDragEndRequestToParentWindow();
            }}
          >
            <div className={styles[`extension-videoplayer-transcript-container`]}>
              <VideoPlayer isSmallVideoPlayer={isSmallVideoPlayer} />
              {applicationType !== "desktop" && isNoteMateOpen && <NoteMateChat />}
              {applicationType === "extension" && isRealTimeTranscriptSupported && <RealTimeWhisperSLTView />}
              {applicationType === "extension" && isAutoNotesSupported && <AutoNotesSideBarView />}
            </div>
            <VideoDocumentEditor />
          </Split>
        )}
      </div>
    </>
  );
};

export default VideoDocument;
