import React, { memo, useRef, useState } from "react";
import styled from "styled-components";
import { Icon } from "@slid/slid-ips";
import { getEventLocationMode } from "utils/utils";
import { trackEvent } from "utils/eventTracking";
import { useTranslation } from "react-i18next";
import { eventTypes } from "types/eventTracking";

const MIN_TEXTAREA_HEIGHT = 18;
const MAX_TEXTAREA_HEIGHT = 216;

export const ChatInput = memo(({ isLoading, onSendChatMessage, chatError }: { isLoading: boolean; onSendChatMessage: (message: string) => void; chatError: boolean | null }) => {
  const { t } = useTranslation("NoteMate");
  const inputBoxRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [userTextInput, setUserTextInput] = useState("");

  React.useLayoutEffect(() => {
    if (!textareaRef.current || !inputBoxRef.current) return;
    // Reset height
    textareaRef.current.style.height = `${MIN_TEXTAREA_HEIGHT}px`;

    // Set height
    const scrollHeight = textareaRef.current.scrollHeight;
    textareaRef.current.style.height = `${Math.min(scrollHeight, MAX_TEXTAREA_HEIGHT)}px`;
    inputBoxRef.current.style.height = `${Math.min(scrollHeight, MAX_TEXTAREA_HEIGHT) + 26}px`;
  }, [userTextInput]);

  const handleMessageSubmit = () => {
    if (isLoading) return;
    if (chatError) return;

    if (userTextInput.trim().length > 0) onSendChatMessage(userTextInput);

    trackEvent({
      eventType: eventTypes.add.ADD_CHAT_MESSAGE_IN_NOTE_MATE_CHAT,
      eventProperties: {
        from: getEventLocationMode(),
      },
    });

    setUserTextInput("");
    return;
  };

  const handleInputChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    if (isLoading) return;
    e.preventDefault();
    setUserTextInput(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const cmdPressed = e.ctrlKey || e.metaKey;

    if (e.key === "Enter" && !e.shiftKey) {
      e?.preventDefault();
      e?.stopPropagation();
      e?.nativeEvent.stopImmediatePropagation();
      handleMessageSubmit();
    }

    if (e.key === "z" && cmdPressed) {
      const textArea = e.target as HTMLTextAreaElement;
      const caretPos = textArea.selectionStart;
      if (caretPos === 0) e.preventDefault();
      // to prevent undo on editor
      e.nativeEvent.stopImmediatePropagation();
      e.stopPropagation();
    }
  };

  return (
    <ChatInputContainer>
      <InputContainer ref={inputBoxRef}>
        <InputBox ref={textareaRef} value={userTextInput} placeholder={t("ChatInputPlaceholder")} onInput={handleInputChange} onKeyDown={handleKeyDown} autoFocus />
        <SendIconContainer
          isInputEmpty={userTextInput.length === 0}
          onClick={(e) => {
            e.preventDefault();
            handleMessageSubmit();
          }}
        >
          <Icon icon="arrowUp24" color={userTextInput.length === 0 ? "var(--gray5)" : "white"} />
        </SendIconContainer>
      </InputContainer>
    </ChatInputContainer>
  );
});

const ChatInputContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 400px;
  padding: 48px 20px 24px 20px;
  background: linear-gradient(180deg, rgba(20, 23, 26, 0) 0%, rgba(20, 23, 26, 0.6) 10%, rgba(20, 23, 26, 0.85) 21.5%, #14171a 30%);
`;

const InputContainer = styled.div`
  width: 100%;
  height: 48px;
  max-height: 240px; /* 최대 높이 (12줄 크기) */

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 12px 16px;
  border-radius: 15px;
  border: 2px solid var(--Primary-Colors-Blue6, #84bff7);
`;

const InputBox = styled.textarea`
  flex: 1 0 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: transparent;
  overflow: auto; /* 최대 높이 도달 시 스크롤 생성 */

  color: var(--white, #fff);
  font-feature-settings:
    "liga" off,
    "clig" off;

  /* en/Body3_400 */
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */

  resize: none; /* 사용자가 사이즈 조절하지 못하도록 */

  &::placeholder {
    color: var(--gray11);
    font-weight: 500;
  }

  // 스크롤 바 숨기기
  &::-webkit-scrollbar {
    width: 0; // 스크롤 바 너비를 0으로 설정
    background: transparent; // 배경을 투명으로 설정
  }
`;

const SendIconContainer = styled.div<{ isInputEmpty: boolean }>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${(props) => (props.isInputEmpty ? "" : "var(--blue7)")};

  &:hover {
    background-color: ${(props) => (props.isInputEmpty ? "" : "var(--blue9)")};
  }
  &:active {
    background-color: ${(props) => (props.isInputEmpty ? "" : "var(--blue9)")};
  }
`;
