import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import { getUserIdToken } from "utils/auth/cognitoAuthToolkit";
import { getTranscriptLanguageCode } from "utils/stt";
import AutoNoteErrorView from "./AutoNoteErrorView";
import AutoNoteLoadingView from "./AutoNoteLoadingView";

import { getApplicationType } from "utils/utils";
import store from "redux/store";
import { AutoNotesLoaderBlockData } from ".";
import useEditorStore from "store/useEditorStore";
import { $createTextNode, $getNodeByKey, $getSelection, $isRangeSelection, $setSelection, LexicalNode, NodeKey } from "lexical";
import { $createSlidParagraphNode } from "../SlidParagraphNode";
import { $convertFromMarkdownString } from "@lexical/markdown";
import { PLAYGROUND_TRANSFORMERS } from "components/NewEditor/plugins/MarkdownTransformers";
const SLID_LLM_API_URL = "https://slidmicroservices.slid.cc/prod-slid-llm-api/v2/auto-notes";
// const SLID_LLM_API_URL = env.end_point_url.slid_llm_api;
// NOTE: for local testing
const SLID_LLM_API_URL_LOCAL = "http://localhost:8000/v2/auto-notes";

const AutoNoteBlockView = ({ newTranscriptBlock, previousTranscriptBlocks, previousNotes, language, currentDocumentKey, nodeKey }: AutoNotesLoaderBlockData & { nodeKey: NodeKey }) => {
  const [autoNotesResult, setAutoNotesResult] = useState<string>("");
  const [error, setError] = useState<string>("");
  const { lexicalEditorRef } = useEditorStore();

  const applicationType = getApplicationType();

  /**
   * Converts the current auto-notes loader block's results into a list block.
   * Each line in the results is prefixed with "- " and becomes a list item. It removes the auto-notes loader block after it's done.
   *
   * @param {API} editorAPI - The Editor.js API instance.
   * @param {BlockAPI | undefined} currentBlock - The current block API instance or undefined.
   * @param {string} text - The text to be converted into list items.
   */
  const insertAutoNotesResultToEditor = async (text: string) => {
    if (!text) {
      return;
    }
    const cleanedText = text.replace(/^\s*\n/gm, "");
    lexicalEditorRef.current?.update(() => {
      const autoNoteBlock = $getNodeByKey(nodeKey);
      if (!autoNoteBlock) return;
      const paragraphNode = $createSlidParagraphNode();
      paragraphNode.append($createTextNode(cleanedText));
      autoNoteBlock.replace(paragraphNode);

      $convertFromMarkdownString(
        paragraphNode.getTextContent(),
        PLAYGROUND_TRANSFORMERS,
        paragraphNode, // node
        false,
        true
      );

      let lastInsertedNode: LexicalNode = paragraphNode;
      // NOTE: this is to prevent the nodes from being created in a nested structure of slid-paragraph node
      paragraphNode.getChildren().forEach((child, index) => {
        lastInsertedNode.insertAfter(child);
        lastInsertedNode = child;
      });
      $setSelection(null);
      paragraphNode.remove();
    });
  };

  const handleDeleteBlock = () => {
    lexicalEditorRef.current?.update(() => {
      const targetNode = $getNodeByKey(nodeKey);
      if (!targetNode) return;
      targetNode.remove();
    });
  };

  useEffect(() => {
    if (autoNotesResult?.length) return;
    const postData = {
      previousTranscriptBlocks: [...previousTranscriptBlocks],
      newTranscriptBlock: {
        finalizedText: newTranscriptBlock.finalizedText,
        blockId: newTranscriptBlock.blockId,
      },
      previousNotes: previousNotes,
      language: getTranscriptLanguageCode(language),
      document_key: currentDocumentKey,
    };

    getUserIdToken().then((cognitoIdToken) => {
      const { userData } = store.getState().slidGlobal;
      const requestBody = { ...postData, user_id: userData.email };
      return (
        axios
          // .post(`${SLID_LLM_API_URL_LOCAL}`, requestBody, {
          .post(`${SLID_LLM_API_URL}`, requestBody, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${cognitoIdToken}`,
            },
          })
          .then((response) => {
            const llmResponse = response.data;
            const autoNotesError = llmResponse?.error;
            if (autoNotesError) {
              Sentry.withScope((scope) => {
                scope.setLevel("error");
                scope.setExtra("message", "An error occurred during auto notes generation\n" + JSON.stringify(autoNotesError));
                Sentry.captureMessage("SLID_WEB_AUTO_NOTE_GENERATION_ERROR");
              });
              setError(autoNotesError);
              return;
            }
            const final_notes = llmResponse?.result;
            const notesText = final_notes; // extractNewNotes(final_notes);
            if (!notesText || notesText.length === 0) {
              handleDeleteBlock();
            }
            setAutoNotesResult(notesText || "");
            insertAutoNotesResultToEditor(notesText || "");
          })
          .catch((error) => {
            console.error("Error fetching auto notes", error);
            if (applicationType === "web") {
              handleDeleteBlock();
            }

            Sentry.withScope((scope) => {
              scope.setLevel("error");
              scope.setExtra("message", "An error occurred when fetching auto notes. \n" + JSON.stringify(error));
              Sentry.captureMessage("SLID_WEB_AUTO_NOTE_FETCHING_ERROR");
            });
            setError(error.message);
          })
      );
    });
  }, []);

  if (error) return <AutoNoteErrorView />;

  return <AutoNoteLoadingView />;
};

export default AutoNoteBlockView;
