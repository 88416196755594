// auth 2.0
import React from "react";
import styled from "styled-components";
import Icon from "../Icon";

const SvgWrapper = styled(Icon)``;

export const TooltipIcon = () => {
  return (
    <SvgWrapper style={{ width: 24, height: 25 }} viewBox={`0 0 24 25`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_529_448)">
        <g clipPath="url(#clip0_529_448)">
          <path d="M19 9.5L20.25 6.75L23 5.5L20.25 4.25L19 1.5L17.75 4.25L15 5.5L17.75 6.75L19 9.5Z" fill="#212529" />
          <path d="M19 15.5L17.75 18.25L15 19.5L17.75 20.75L19 23.5L20.25 20.75L23 19.5L20.25 18.25L19 15.5Z" fill="#212529" />
          <path d="M11.5 10L9 4.5L6.5 10L1 12.5L6.5 15L9 20.5L11.5 15L17 12.5L11.5 10ZM9.99 13.49L9 15.67L8.01 13.49L5.83 12.5L8.01 11.51L9 9.33L9.99 11.51L12.17 12.5L9.99 13.49Z" fill="#212529" />
        </g>
        <defs>
          <clipPath id="clip0_529_448">
            <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
          </clipPath>
        </defs>
      </g>
    </SvgWrapper>
  );
};
