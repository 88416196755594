import { create } from "zustand";

type NoteMateStoreType = {
  isNoteMateOpen: boolean;
  openNoteMate: () => void;
  closeNoteMate: () => void;
};

const useNoteMateStore = create<NoteMateStoreType>()((set) => ({
  isNoteMateOpen: false,
  openNoteMate: () => set({ isNoteMateOpen: true }),
  closeNoteMate: () => set({ isNoteMateOpen: false }),
}));

export default useNoteMateStore;
