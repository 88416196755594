import { Icon, Typography13, Typography15 } from "@slid/slid-ips";
import { TooltipIcon } from "components/icons/NoteMateIcons/TooltipIcon";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export const GuideTooltip = ({ hideTooltip }: { hideTooltip: () => void }) => {
  const { t } = useTranslation("NoteMate");

  return (
    <GuideTooltipContainer>
      <LeftContainer>
        <TooltipIcon />
        <TextContainer>
          <Typography15 weight={700} text={t("GuideTooltipTitle")} color={`--gray17`} />
          <Typography13 weight={400} text={t("GuideTooltipContent")} color={`--gray17`} />
        </TextContainer>
      </LeftContainer>
      <CloseButtonContainer icon="x24" color={`--purple7`} onClick={hideTooltip} />
    </GuideTooltipContainer>
  );
};

const GuideTooltipContainer = styled.div`
  display: flex;
  width: 298px;
  height: auto;
  justify-content: space-between;

  padding: 8px 8px 8px 16px;
  border-radius: 8px;
  border: 2px solid var(--purple7);
  background: var(--purple1);
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0px;
  gap: 2px;
`;

const CloseButtonContainer = styled(Icon)`
  && {
    cursor: pointer;
  }
`;
