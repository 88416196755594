import { Typography28, Typography15, Typography11, Typography14 } from "@slid/slid-ips";
import { LargeNoteMateIcon } from "components/icons/NoteMateIcons/LargeNoteMateIcon";

import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { eventTypes } from "types/eventTracking";
import { trackEvent } from "utils/eventTracking";
import { getEventLocationMode } from "utils/utils";

export const NewChatPlaceholder = ({ sendChatMessage }: { sendChatMessage: (question: string) => void }) => {
  const { t } = useTranslation("NoteMate");

  const handlePlaceholderClick = (content: { title: string; question: string; type: string }, index: number) => {
    const placeholderListEn = t("PlaceholderList", { returnObjects: true, lng: "en" });
    const clickedContent = placeholderListEn[index].title.toLowerCase();

    trackEvent({
      eventType: eventTypes.click.EXAMPLE_CHAT_IN_NOTE_MATE_CHAT,
      eventProperties: {
        from: getEventLocationMode(),
        category: clickedContent,
      },
    });
    sendChatMessage(content.question);
  };

  const placeholderList = t("PlaceholderList", { returnObjects: true }) as { title: string; question: string; type: string }[];

  return (
    <NewChatPlaceholderContainer>
      <PlaceholderHeader>
        <LargeNoteMateIcon />
        <PlaceholderTitle>
          <Typography28 weight={700} color="--gray2" text={t("PlaceholderTitle")} />
          <Typography15 weight={400} color="--gray9" text={t("PlaceholderContent")} />
        </PlaceholderTitle>
      </PlaceholderHeader>
      <PlaceholderContent>
        {placeholderList.map((content, index) => (
          <PlaceholderContentItem key={index} type={content.type} onClick={() => handlePlaceholderClick(content, index)}>
            <Typography11 weight={500} color="--blue4" text={content.title} />
            <Typography14 weight={500} color="--white" text={content.question} />
          </PlaceholderContentItem>
        ))}
      </PlaceholderContent>
    </NewChatPlaceholderContainer>
  );
};

const NewChatPlaceholderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 60px;
`;

const PlaceholderHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const PlaceholderTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const PlaceholderContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 12px;
`;

const PlaceholderContentItem = styled.div<{ type: string }>`
  width: ${(props) => (props.type === "stretch" ? "100%" : "auto")};
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 12px;
  background: #1a253c;
  gap: 6px;
  cursor: pointer;
  :hover {
    background: #324466;
  }
  :active {
    background: #324466;
  }
`;
