import { create } from "zustand";

interface SlidLexicalAddButtonStore {
  targetBlockElem: HTMLElement | null;
  setTargetBlockElem: (block: HTMLElement | null) => void;
  isMenuOpen: boolean;
  setIsMenuOpen: (isOpen: boolean) => void;
}

export const useSlidLexicalAddButtonStore = create<SlidLexicalAddButtonStore>((set) => ({
  targetBlockElem: null,
  setTargetBlockElem: (block) => set({ targetBlockElem: block }),
  isMenuOpen: false,
  setIsMenuOpen: (isOpen) => set({ isMenuOpen: isOpen }),
}));
