// auth 2.0
import React from "react";
import styled from "styled-components";
import Icon from "./Icon";
import { SvgIconProps } from "./types";

const SvgWrapper = styled(Icon)``;

export const CopyIcon = ({ width, height }: SvgIconProps) => {
  return (
    <SvgWrapper viewBox={`0 0 ${width} ${height}`} style={{ width, height }}>
      <g clipPath="url(#clip0_552_382)">
        <path
          d="M12.1997 5.19998H6.59971C5.82651 5.19998 5.19971 5.82678 5.19971 6.59998V12.2C5.19971 12.9732 5.82651 13.6 6.59971 13.6H12.1997C12.9729 13.6 13.5997 12.9732 13.5997 12.2V6.59998C13.5997 5.82678 12.9729 5.19998 12.1997 5.19998Z"
          stroke="#84BFF7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.7999 5.19999V3.79999C10.7999 3.42869 10.6524 3.0726 10.3899 2.81004C10.1273 2.54749 9.77121 2.39999 9.3999 2.39999H3.7999C3.4286 2.39999 3.0725 2.54749 2.80995 2.81004C2.5474 3.0726 2.3999 3.42869 2.3999 3.79999V9.39999C2.3999 9.7713 2.5474 10.1274 2.80995 10.3899C3.0725 10.6525 3.4286 10.8 3.7999 10.8H5.1999"
          stroke="#84BFF7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_552_382">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
};
