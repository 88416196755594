import { Icon } from "@slid/slid-ips";
import { Typography14 } from "@slid/slid-ips";
import { Typography20 } from "@slid/slid-ips";
import React from "react";
import styled from "styled-components";
import useNoteMateStore from "store/useNoteMateStore";
import { Message } from "ai/react";
import { trackEvent } from "utils/eventTracking";
import { getEventLocationMode } from "utils/utils";
import { useTranslation } from "react-i18next";
import { eventTypes } from "types/eventTracking";

const ChatHeader = ({ messages, handleClearChat }: { messages: Message[]; handleClearChat: () => void }) => {
  const { closeNoteMate } = useNoteMateStore();
  const { t } = useTranslation("NoteMate");

  const handleOpenNewChat = () => {
    trackEvent({
      eventType: eventTypes.click.NEW_CHAT_IN_NOTE_MATE_CHAT,
      eventProperties: {
        from: getEventLocationMode(),
      },
    });
    handleClearChat();
  };

  const handleCloseChat = () => {
    trackEvent({
      eventType: eventTypes.click.CLOSE_NOTE_MATE_CHAT,
      eventProperties: {
        from: getEventLocationMode(),
      },
    });
    closeNoteMate();
  };

  return (
    <ChatHeaderContainer isNewChat={messages.length === 0}>
      {messages.length > 0 && (
        <TitleContainer>
          <Typography20 weight={700} color="--gray2" text={t("NoteMate")} />
          <NewChatButton onClick={handleOpenNewChat}>
            <Typography14 weight={700} color="--gray6" text={t("NewChatButton")} />
          </NewChatButton>
        </TitleContainer>
      )}
      <CloseChatButton>
        <Icon icon="x24" color="white" onClick={handleCloseChat} style={{ cursor: "pointer" }} />
      </CloseChatButton>
    </ChatHeaderContainer>
  );
};

export default ChatHeader;

const ChatHeaderContainer = styled.div<{ isNewChat: boolean }>`
  width: 100%;
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: ${({ isNewChat }) => (isNewChat ? "flex-end" : "space-between")};
  gap: 8px;
`;

const TitleContainer = styled.div`
  width: calc(100% - 24px);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NewChatButton = styled.div`
  cursor: pointer;
`;

const CloseChatButton = styled.div`
  height: 28px;
  display: flex;
  justify-content: center;
  cursor: pointer;
`;
