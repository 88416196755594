// auth 2.0
import React from "react";
import styled from "styled-components";
import Icon from "../Icon";

const SvgWrapper = styled(Icon)``;

export const SmallNoteMateIcon = () => {
  return (
    <SvgWrapper style={{ width: 28, height: 28 }} viewBox={`0 0 28 28`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Smart Search" clipPath="url(#clip0_516_1119)">
        <path
          id="Subtract"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25 14C25 20.0751 20.0751 25 14 25C7.92487 25 3 20.0751 3 14C3 7.92487 7.92487 3 14 3C20.0751 3 25 7.92487 25 14ZM15.0768 8.2469C14.803 7.11768 13.197 7.11768 12.9232 8.2469L12.1697 11.354C12.072 11.7572 11.7572 12.072 11.354 12.1697L8.2469 12.9232C7.11768 13.197 7.11768 14.803 8.2469 15.0768L11.354 15.8302C11.7572 15.928 12.072 16.2428 12.1697 16.646L12.9232 19.7531C13.197 20.8823 14.803 20.8823 15.0768 19.7531L15.8302 16.646C15.928 16.2428 16.2428 15.928 16.646 15.8302L19.7531 15.0768C20.8823 14.803 20.8823 13.197 19.7531 12.9232L16.646 12.1697C16.2428 12.072 15.928 11.7572 15.8302 11.354L15.0768 8.2469Z"
          fill="url(#paint0_linear_516_1119)"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_516_1119" x1="3" y1="3" x2="25" y2="25" gradientUnits="userSpaceOnUse">
          <stop offset="0.26" stopColor="#84BFF7" />
          <stop offset="0.885" stopColor="#0083FF" />
        </linearGradient>
        <clipPath id="clip0_516_1119">
          <rect width={28} height={28} fill="white" transform={`matrix(-1 0 0 1 28 0)`} />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
};
