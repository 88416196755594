// auth 2.0
import React from "react";
import styled from "styled-components";
import Icon from "../Icon";

const SvgWrapper = styled(Icon)``;

export const MediumNoteMateIcon = () => {
  return (
    <SvgWrapper style={{ width: 40, height: 40 }} viewBox={`0 0 40 40`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_526_1746)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.7144 20C35.7144 28.6788 28.6788 35.7143 20.0001 35.7143C11.3213 35.7143 4.28578 28.6788 4.28578 20C4.28578 11.3212 11.3213 4.28572 20.0001 4.28572C28.6788 4.28572 35.7144 11.3212 35.7144 20ZM21.5384 11.7813C21.1472 10.1681 18.8529 10.1681 18.4617 11.7813L17.3854 16.22C17.2457 16.796 16.7961 17.2457 16.2201 17.3853L11.7814 18.4617C10.1682 18.8528 10.1682 21.1471 11.7814 21.5383L16.2201 22.6146C16.7961 22.7543 17.2457 23.204 17.3854 23.7799L18.4617 28.2187C18.8529 29.8319 21.1472 29.8319 21.5384 28.2187L22.6147 23.7799C22.7544 23.204 23.2041 22.7543 23.78 22.6146L28.2187 21.5383C29.8319 21.1471 29.8319 18.8528 28.2187 18.4617L23.78 17.3853C23.2041 17.2457 22.7544 16.796 22.6147 16.22L21.5384 11.7813Z"
          fill="url(#paint0_linear_526_1746)"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_526_1746" x1="4.28579" y1="4.28572" x2="35.7144" y2="35.7143" gradientUnits="userSpaceOnUse">
          <stop offset="0.26" stopColor="#84BFF7" />
          <stop offset="0.885" stopColor="#0083FF" />
        </linearGradient>
        <clipPath id="clip0_526_1746">
          <rect width="40" height="40" fill="white" transform="matrix(-1 0 0 1 40 0)" />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
};
