import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ChatInput } from "./ChatInput";
import { useChat } from "ai/react";
import { saveMessageToFirebase, setAppendToSliddyMessages, setCurrentFirebaseChatId, clearAiSliddyMessages, setAlignAISessionId } from "redux/modules/aiSliddySlice";
import env from "config/env";
import * as Sentry from "@sentry/browser";
import store from "redux/store";
import { ChatBox } from "./ChatBox";
import { useAppDispatch } from "hooks";
import { useAppSelector } from "hooks";
import ChatHeader from "./ChatHeader";
import { getUserIdToken } from "utils/auth/cognitoAuthToolkit";
import { getUserLocationInfo, nanoid } from "utils/utils";
import { exportToSlidGPT } from "utils/exportNote";
import { NewChatPlaceholder } from "./NewChatPlaceholder";
import useNoteMateStore from "store/useNoteMateStore";
import { setShowSmartLiveTextView } from "redux/actions/sttActions";
import useWhisperSLTStore from "store/useWhisperSLTStore";
import { setIsAutoNotesSettingsOpen } from "redux/modules/autoNotesSlice";
import { trackEvent } from "utils/eventTracking";
import { eventTypes } from "types/eventTracking";

// role enum
export enum SLIDDY_CHAT_ROLE {
  "user" = "user",
  "assistant" = "assistant",
  "system" = "system",
}

interface Message {
  id: string;
  role: SLIDDY_CHAT_ROLE;
  content: string;
}

interface UserCountryInfo {
  country: string;
  country_3: string;
  ip: string;
  name: string;
}

const SLIDDY_API_URL = env.end_point_url.ai_sliddy_api;

const NoteMateChat = () => {
  const { lang, userData } = useAppSelector((state) => state.slidGlobal);
  const { sliddyMessages, alignAISessionId } = useAppSelector((state) => state.aiSliddy);
  const { isNoteMateOpen } = useNoteMateStore();
  const { setShowSmartLiveTextView: setShowWhisperSmartLiveTextView } = useWhisperSLTStore();
  const dispatch = useAppDispatch();
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [slidNoteContent, setSlidNoteContent] = useState(null);
  const [userAuthToken, setUserAuthToken] = useState("");
  const [isThinking, setIsThinking] = useState(false);
  const [userCountryInfo, setUserCountryInfo] = useState<null | UserCountryInfo>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [isEndVisible, setIsEndVisible] = useState<boolean>(true);
  const parentRef = useRef<HTMLDivElement>(null);

  const {
    messages,
    setMessages,
    error: chatError,
    isLoading,
    append,
    stop: stopGeneration, // aborts the request but returns all the produced tokens.
    reload, // will regenerate the last message
  } = useChat({
    api: SLIDDY_API_URL,
    headers: {
      Authorization: userAuthToken,
    },
    initialMessages: sliddyMessages,
    body: {
      sessionId: store.getState().aiSliddy.alignAISessionId,
      userId: userData?.user_key,
      userEmail: userData?.email,
      userCountryInfo: userCountryInfo,
      lang: lang,
      slidNote: slidNoteContent,
    },
    onResponse: (response) => {
      setIsThinking(false);
    },
    onFinish: (message) => {
      dispatch(setAppendToSliddyMessages(message));
      dispatch(saveMessageToFirebase(message));
    },
    onError(err) {
      setShowErrorBanner(true);
      if (env.currentEnv === "development") {
        console.log("Sliddy chat error - ", err);
      } else {
        Sentry.withScope((scope) => {
          scope.setLevel("error");
          Sentry.captureMessage("AI Sliddy error");
          scope.setExtra("error", err);
        });
      }
    },
  });

  useEffect(() => {
    if (isNoteMateOpen) {
      trackEvent({
        eventType: eventTypes.show.NOTE_MATE_IN_VIDEO_NOTE_PAGE,
      });
      dispatch(setShowSmartLiveTextView(false));
      setShowWhisperSmartLiveTextView(false);
      dispatch(setIsAutoNotesSettingsOpen(false));
    }
  }, [isNoteMateOpen, dispatch]);

  useEffect(() => {
    const endOfMessagesRef = messagesEndRef.current;
    const observer = new IntersectionObserver((entries) => {
      setIsEndVisible(entries[0].isIntersecting);
    });

    if (endOfMessagesRef) {
      observer.observe(endOfMessagesRef);
    }

    return () => {
      if (endOfMessagesRef) {
        observer.unobserve(endOfMessagesRef);
      }
    };
  }, []);

  useEffect(() => {
    if (!isEndVisible) return;
    scrollMessagesList();
  }, [messages, isEndVisible]);

  useEffect(() => {
    if (isThinking) scrollMessagesList();
  }, [isThinking, isEndVisible, messages]);

  const scrollMessagesList = () => {
    if (parentRef.current) {
      parentRef.current.scrollTop = parentRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    getUserLocationInfo()
      .then((countryInfo) => {
        setUserCountryInfo(countryInfo);
      })
      .catch((err) => {
        if (env.currentEnv === "development") console.log("Request to fetch user country info has been blocked by ad blocker- ", err);
      });
  }, []);

  const updateSlidNoteContentState = () => {
    const NOTE_CONTENT_PLACEHOLDER = lang === "ko" ? "# 내용 없는  슬리드 노트\n" : "# Empty Slid note markdown content.\n";

    return exportToSlidGPT()
      .then((result) => {
        let content: string = "";
        if (result) {
          content = result;
        } else {
          content = NOTE_CONTENT_PLACEHOLDER;
        }
        // @ts-ignore
        return setSlidNoteContent({ content: content });
      })
      .catch((err) => {
        console.log("Error obtaining slid note for sending to slid gpt ", err);
        return err;
      });
  };

  const handleSendUserChatMessage = (message: string) => {
    setIsThinking(true);
    getUserIdToken()
      .then((token) => {
        setUserAuthToken(token);
      })
      .then(() => {
        if (!alignAISessionId) {
          const newSessionId = nanoid();
          dispatch(setAlignAISessionId(newSessionId));
        }
      })
      .then(() => updateSlidNoteContentState())
      .then(() => {
        append({
          role: "user",
          content: message,
        });
        dispatch(setAppendToSliddyMessages({ role: SLIDDY_CHAT_ROLE.user, content: message }));
        dispatch(saveMessageToFirebase({ role: SLIDDY_CHAT_ROLE.user, content: message }));
      })
      .catch((err) => {
        if (env.currentEnv === "development") {
          console.log("Error sending message to AI sliddy - ", err);
        } else {
          Sentry.withScope((scope) => {
            scope.setLevel("error");
            Sentry.captureMessage("Error before sending message to AI sliddy");
            scope.setExtra("error", err);
          });
        }
      });
  };

  const handleClearChat = () => {
    setShowErrorBanner(false);
    stopGeneration();
    setMessages([]);
    setIsThinking(false);
    dispatch(setCurrentFirebaseChatId(null));
    dispatch(clearAiSliddyMessages());
    dispatch(setAlignAISessionId(null));
  };

  return (
    <NoteMateSideBarContainer>
      <ChatHeader messages={messages} handleClearChat={handleClearChat} />
      <ChatContainer>
        {messages.length === 0 ? (
          <NewChatPlaceholder sendChatMessage={handleSendUserChatMessage} />
        ) : (
          <>
            <ChatBox messages={messages} isThinking={isThinking} isLoading={isLoading} parentRef={parentRef} chatError={showErrorBanner} />
            <MessagesScrollAnchor ref={messagesEndRef} />
          </>
        )}
      </ChatContainer>
      <ChatInput isLoading={isLoading} onSendChatMessage={handleSendUserChatMessage} chatError={showErrorBanner} />
    </NoteMateSideBarContainer>
  );
};

export default NoteMateChat;

const NoteMateSideBarContainer = styled.div`
  width: 400px;
  min-width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--gray18);
`;

const ChatContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  padding: 0px 20px;
  background-color: var(--gray18);
`;

const MessagesScrollAnchor = styled.div`
  width: 100%;
`;
