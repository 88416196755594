// auth 2.0
import React from "react";
import styled from "styled-components";
import Icon from "../Icon";

const SvgWrapper = styled(Icon)``;

export const LargeNoteMateIcon = () => {
  return (
    <SvgWrapper style={{ width: 60, height: 60 }} viewBox={`0 0 60 60`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="chat_profile" clipPath="url(#clip0_526_1509)">
        <path
          id="Subtract"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30ZM34.9916 22.7837L32.9368 14.3098C32.19 11.2301 27.81 11.2301 27.0632 14.3098L25.0084 22.7837C24.7418 23.8833 23.8833 24.7418 22.7837 25.0084L14.3098 27.0632C11.2301 27.81 11.2301 32.19 14.3098 32.9368L22.7837 34.9916C23.8833 35.2582 24.7418 36.1167 25.0084 37.2163L27.0632 45.6902C27.81 48.7699 32.19 48.7699 32.9368 45.6902L34.9916 37.2163C35.2582 36.1167 36.1167 35.2582 37.2163 34.9916L45.6902 32.9368C48.7699 32.19 48.7699 27.81 45.6902 27.0632L37.2163 25.0084C36.1167 24.7418 35.2582 23.8833 34.9916 22.7837Z"
          fill="url(#paint0_linear_526_1509)"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_526_1509" x1="7.15256e-06" y1="0" x2="60" y2="60" gradientUnits="userSpaceOnUse">
          <stop offset="0.26" stopColor="#84BFF7" />
          <stop offset="0.885" stopColor="#0083FF" />
        </linearGradient>
        <clipPath id="clip0_526_1509">
          <rect width="60" height="60" fill="white" />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
};
