import React, { useEffect, useState } from "react";
import { Message } from "ai/react";
import { Icon, Typography12, Typography13 } from "@slid/slid-ips";
import styled from "styled-components";
import { MediumNoteMateIcon } from "components/icons/NoteMateIcons/MediumNoteMateIcon";
import { CopyIcon } from "components/icons/CopyIcon";
import { SLIDDY_CHAT_ROLE } from ".";
import loading_animation_blue from "components/animations/loading_animation_blue.json";
import Lottie from "react-lottie";
import { useTranslation } from "react-i18next";
import { eventTypes } from "types/eventTracking";
import { trackEvent } from "utils/eventTracking";

const defaultLottieOptions = {
  loop: true,
  autoplay: true,
  animationData: loading_animation_blue,
  renderer: "svg",
};

export const ChatBox = ({
  messages,
  isThinking,
  isLoading,
  parentRef,
  chatError,
}: {
  messages: Message[];
  isThinking: boolean;
  isLoading: boolean;
  parentRef: React.RefObject<HTMLDivElement>;
  chatError: boolean | null;
}) => {
  const AI_ROLE = SLIDDY_CHAT_ROLE.assistant;
  const { t } = useTranslation("NoteMate");
  const [copiedMessageId, setCopiedMessageId] = useState<number | null>(null);

  useEffect(() => {
    if (copiedMessageId) {
      setTimeout(() => {
        setCopiedMessageId(null);
      }, 3000);
    }
  }, [copiedMessageId]);

  useEffect(() => {
    if (chatError) {
      trackEvent({
        eventType: eventTypes.show.ERROR_IN_NOTE_MATE,
      });
    }
  }, [chatError]);

  const renderMessageText = (messageContent, messageRole, isBeingGenerated, messageIndex, messageCount) => {
    const isLastMessage = messageIndex === messageCount - 1;
    const shouldAppendLoadingDots = messageRole === AI_ROLE && isBeingGenerated && isLastMessage;
    const messageText = `${messageContent.trim()}${shouldAppendLoadingDots ? "..." : ""}`;
    return <Typography13 text={messageText} color={`--white`} weight={400} />;
  };

  return (
    <ChatBoxContainer ref={parentRef}>
      {messages.map((messageData, index: number) => (
        <MessageContainer key={index} isAiMessage={messageData.role === AI_ROLE}>
          {messageData.role === AI_ROLE && <MediumNoteMateIcon />}
          <MessageWrapper isAiMessage={messageData.role === AI_ROLE} isThinking={false}>
            <MessageItem key={index} isAiMessage={messageData.role === AI_ROLE}>
              {renderMessageText(messageData.content, messageData.role, isLoading, index, messages.length)}
            </MessageItem>
            {messageData.role === AI_ROLE && (
              <>
                {copiedMessageId === index ? (
                  <CopyContainer>
                    <Icon icon="check24" width={16} height={16} color="--gray9" />
                    <Typography12 weight={500} color="--gray9" text={t("ChatCopySuccessMessage")} />
                  </CopyContainer>
                ) : (
                  <CopyContainer
                    onClick={() => {
                      setCopiedMessageId(index);
                      navigator.clipboard.writeText(messageData.content);
                    }}
                  >
                    <CopyIcon width={16} height={16} />
                    <Typography12 weight={500} color="--blue6" text={t("ChatCopyButton")} />
                  </CopyContainer>
                )}
              </>
            )}
          </MessageWrapper>
          {index === messages.length - 1 && <ChatBoxFooter />}
        </MessageContainer>
      ))}
      {isThinking && (
        <MessageContainer isAiMessage={true}>
          <MediumNoteMateIcon />
          <MessageWrapper isAiMessage={true} isThinking={true}>
            <LoadingContainer>
              <Lottie options={defaultLottieOptions} height={28} width={28} />
            </LoadingContainer>
          </MessageWrapper>
        </MessageContainer>
      )}
      {chatError && (
        <MessageContainer isAiMessage={true}>
          <MediumNoteMateIcon />
          <ErrorMessageWrapper isAiMessage={true} isThinking={false}>
            <Typography13 text={t("ChatErrorMessage")} color={`--white`} weight={400} />
          </ErrorMessageWrapper>
        </MessageContainer>
      )}
    </ChatBoxContainer>
  );
};

const ChatBoxContainer = styled.div`
  width: 100%;
  height: calc(100% - 72px);
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;

  // 스크롤 바 숨기기
  &::-webkit-scrollbar {
    width: 0; // 스크롤 바 너비를 0으로 설정
    background: transparent; // 배경을 투명으로 설정
  }
`;

const MessageContainer = styled.div<{ isAiMessage: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: ${({ isAiMessage }) => (isAiMessage ? `flex-start` : `flex-end`)};

  gap: 8px;
`;

const MessageWrapper = styled.div<{ isAiMessage: boolean; isThinking: boolean }>`
  display: flex;
  width: ${(props) => (props.isThinking ? "100%" : "fit-content")};
  flex-direction: column;
  align-items: flex-start;

  padding: 12px 16px;
  background-color: ${({ isAiMessage }) => (isAiMessage ? "var(--gray17)" : "var(--blue11)")};
  gap: 12px;
  border-radius: 12px;
`;

const MessageItem = styled.div<{ isAiMessage: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CopyContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 4px;
`;

const LoadingContainer = styled.div``;

const ErrorMessageWrapper = styled(MessageWrapper)`
  width: 100%;
  background-color: var(--red1_opacity);
`;

const ChatBoxFooter = styled.div`
  width: 100%;
  height: 32px;
`;
