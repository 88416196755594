import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SmallNoteMateIcon } from "components/icons/NoteMateIcons/SmallNoteMateIcon";
import { Typography16 } from "@slid/slid-ips";
import { GuideTooltip } from "./GuideTooltip";
import { getDefaultSettingCookie, setDefaultSettingCookie } from "utils/cookies/cookies";
import env from "config/env";
import useNoteMateStore from "store/useNoteMateStore";
import { useTranslation } from "react-i18next";
import { useConfirmPrivilege } from "utils/customHook/useConfirmPrivilege";
import { SlidFeatures } from "utils/privilegeManager";
import { eventTypes } from "types/eventTracking";
import { trackEvent } from "utils/eventTracking";

export const NoteMateButton = ({ isSmallVideoPlayer }: { isSmallVideoPlayer?: boolean }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const { openNoteMate, isNoteMateOpen } = useNoteMateStore();
  const { t } = useTranslation("NoteMate");
  const { confirmPrivilege, showInsufficientPrivilegeModal } = useConfirmPrivilege();

  useEffect(() => {
    const isTooltipVisible = getDefaultSettingCookie({ property: "isNoteMateTooltipVisible" });

    // If there is no cookie, create a cookie and show the tooltip
    if (isTooltipVisible === undefined) {
      setDefaultSettingCookie({ property: "isNoteMateTooltipVisible", value: true });
      setIsTooltipVisible(true);
    }
    // Show tooltip if cookie is true
    if (isTooltipVisible === true) {
      setIsTooltipVisible(true);
    }
    // Don't show tooltip if cookie is false
    if (isTooltipVisible === false) {
      setIsTooltipVisible(false);
    }

    // Show tooltip in development environment
    if (env.currentEnv === "development") {
      setDefaultSettingCookie({ property: "isNoteMateTooltipVisible", value: true });
      setIsTooltipVisible(true);
    }
  }, []);

  const hideTooltip = () => {
    setIsTooltipVisible(false);
    setDefaultSettingCookie({ property: "isNoteMateTooltipVisible", value: false });
  };

  const handleNoteMateButtonClick = () => {
    if (isTooltipVisible) {
      hideTooltip();
    }

    trackEvent({
      eventType: eventTypes.click.NOTE_MATE_IN_VIDEO_NOTE_PAGE,
    });

    if (!confirmPrivilege(SlidFeatures.noteMate)) return showInsufficientPrivilegeModal();

    openNoteMate();
  };

  if (isNoteMateOpen) return null;

  return (
    <NoteButtonWrapper>
      <NoteMateButtonContainer onClick={handleNoteMateButtonClick} isTooltipVisible={isTooltipVisible}>
        <SmallNoteMateIcon />
        {!isSmallVideoPlayer ? <Typography16 text={t("NoteMate")} color={`--gray1`} weight={500} /> : null}
      </NoteMateButtonContainer>
      {isTooltipVisible && (
        <TooltipContainer>
          <GuideTooltip hideTooltip={hideTooltip} />
        </TooltipContainer>
      )}
    </NoteButtonWrapper>
  );
};

const NoteButtonWrapper = styled.div`
  position: absolute;
  right: 16px;
  top: 14px;
`;

const NoteMateButtonContainer = styled.div<{ isTooltipVisible: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 8px;
  gap: 4px;
  border: 2px solid transparent;

  ${({ isTooltipVisible }) =>
    isTooltipVisible &&
    `
    border: 2px solid var(--purple7);
  `}
  :hover {
    background: var(--gray14);
  }
  :active {
    background: var(--gray15);
  }
`;

const TooltipContainer = styled.div`
  position: absolute;
  right: -4px;
  bottom: 56px;
`;
